import {Component, Input} from '@angular/core';
import {SwPush} from '@angular/service-worker';
import {PushApiService} from "../../api-services";
import {WebStandardSubscription} from "../../api-models";
import {Router} from "@angular/router";

@Component({
  selector: 'app-push-subscriber',
  templateUrl: './push-subscriber.component.html',
  styleUrls: ['./push-subscriber.component.scss']
})

export class PushSubscriberComponent {

  @Input()
  public showTestButton = false;

  private pushSubscription: PushSubscription;
  public isSubscribed = false;

  public constructor(
    private readonly swPush: SwPush,
    private readonly pushApiService: PushApiService,
    private readonly router: Router) {

    swPush.subscription.subscribe((subscription) => {
      this.pushSubscription = subscription;
      this.isSubscribed = !!this.pushSubscription;
    });

    swPush.notificationClicks.subscribe((click) => {
      const url = click.action;
      window.focus();
      window.open(url, '_self');
    })
  };

  public toggleSubscription() {
    if (!this.isSubscribed) {
      this.subscribe();
    } else {
      this.unsubscribe();
    }
  };

  public subscribe() {
    this.pushApiService.getVapidPublicKey().subscribe(publicKey => {
      this.swPush.requestSubscription({
        serverPublicKey: publicKey
      })
        .then(webStandardSub => {
          this.pushApiService.subscribe(new WebStandardSubscription(webStandardSub.toJSON() as unknown))
            .subscribe(
              () => {
                this.isSubscribed = !!this.pushSubscription;
              },
              error => console.error(error)
            )
        })
        .catch(error => console.error(error));
    }, error => console.error(error));
  };

  public unsubscribe() {
    this.pushApiService.unsubscribe(new WebStandardSubscription(this.pushSubscription.toJSON() as unknown));
    this.swPush.unsubscribe().then(() => {
      this.pushSubscription = undefined;
      this.isSubscribed = false;
    });
  }
}
