import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {LocalizationService, UiModule} from '@xws/ui';
import {IconsModule} from '@xws/icons';
import {TranslationService} from "@xws/translation";
import {ResultsComponent} from './components/results/results.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PushSubscriberComponent} from './components/push-subscriber/push-subscriber.component';
import {AgoPipe} from './pipes/ago.pipe';
import {IsNewPipe} from './pipes/is-new.pipe';
import {PullToRefreshComponent} from './components/pull-to-refresh/pull-to-refresh.component';
import {LoaderIOSComponent} from './components/pull-to-refresh/loader-ios/loader-ios.component';
import {LoaderAndroidComponent} from './components/pull-to-refresh/loader-android/loader-android.component';
import {LoginComponent} from './components/login/login.component';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {AuthService} from "./services/auth.service";
import {PluginsComponent} from "./components/plugins/plugins.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ResultsComponent,
    PushSubscriberComponent,
    AgoPipe,
    IsNewPipe,
    PullToRefreshComponent,
    LoaderIOSComponent,
    LoaderAndroidComponent,
    LoginComponent,
    PluginsComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    UiModule,
    IconsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appModule: AppModule) => () => appModule.initialize(),
      deps: [AppModule],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(
    private readonly localizationService: LocalizationService,
    private readonly translationService: TranslationService,
    private readonly authService: AuthService) {
    translationService.setLanguage('de');
    this.localizationService.registerLanguage('de');
    this.localizationService.setSupportedLanguages([{code: 'de', name: 'de'}]);
    this.translationService.setDefaultLanguage('de');
  }

  private initialize(): void {
    this.authService.initialize();
  }
}
