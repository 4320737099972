import {Injectable} from '@angular/core';
import {ResultEntry} from "./api-models/result-entry";
import {EntriesApiService} from "./api-services";
import {ReplaySubject} from "rxjs";
import {startWith} from "rxjs/operators";
import {AuthService} from "./services/auth.service";

export class Entry extends ResultEntry {
  public isNew: boolean;
  public isFavorite: boolean;

  public constructor(public init: Partial<Entry> = undefined) {
    super();
    if (init) {
      Object.assign(this, init);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public entries: Entry[] = [];
  public entryStartNum = 0;
  public lastVisited: Date;
  public favorites: string[] = [];
  private isLoading = false;
  private readonly isLoadedSubject = new ReplaySubject<boolean>(1);
  public isLoaded$ = this.isLoadedSubject.asObservable().pipe(startWith(this.isLoading));
  public hideSold = true;

  public constructor(
    private readonly authService: AuthService,
    private readonly entriesApiService: EntriesApiService) {
  }

  public initialize(): void {
    this.authService.authorized.subscribe(isAuthorized => {
      if (isAuthorized) {
        this.loadLastVisited();
        this.loadFavorites();
        this.refresh();
      }
    });
  }

  private loadLastVisited() {
    this.lastVisited = this.convertToDate(localStorage.getItem("last-visited"));
    if (!this.lastVisited)
      this.lastVisited = new Date(Date.now());
  }

  public convertToDate(value: string | Date): Date {
    return typeof (value) === "string" ? new Date(value) : value;
  }

  public reset(): void {
    this.setLastVisited();
    this.entries = [];
    this.entryStartNum = 0;
    this.refresh();
  }

  public refresh(): void {
    this.loadAdditionalEntries(10);
  }

  public loadAdditionalEntries(numOfEntries: number) {
    this.isLoadedSubject.next(false);
    if (this.isLoading)
      return;

    this.isLoading = true;
    this.entriesApiService.getEntries({
      entryRequestNum: numOfEntries,
      entryStartNum: this.entryStartNum
    }).subscribe(resultEntries => {

      this.isLoadedSubject.next(true);
      const entries = resultEntries.map(e => new Entry(e));
      entries.forEach(newEntry => {
        newEntry.isFavorite = this.favorites.includes(newEntry.uid)
        if (newEntry.collectedOn > this.lastVisited) {
          newEntry.isNew = true;
        } else {
          this.setLastVisited();
        }
      });
      this.entries = [...this.entries, ...entries];

      let data = new Map();
      for (let obj of this.entries) {
        data.set(obj.uid, obj);
      }

      this.entries = [...data.values()];
      this.entryStartNum = this.entries.length;
      this.isLoading = false;
    });
  }

  public toggleFavorite(entry: Entry) {
    if (!entry.isFavorite)
      this.favorites.push(entry.uid);
    else
      this.favorites.remove(entry.uid);
    this.saveFavorites();
    entry.isFavorite = !entry.isFavorite;
  }

  private loadFavorites() {
    const tempFavorites = JSON.parse(localStorage.getItem("favorites")) as string[];
    if (tempFavorites) {
      this.favorites = tempFavorites;
    } else {
      this.favorites = [];
    }
  }

  private saveFavorites() {
    localStorage.setItem("favorites", JSON.stringify(this.favorites));
  }

  private setLastVisited() {
    this.lastVisited = new Date(Date.now());
    localStorage.setItem("last-visited", this.lastVisited.toString())
  }

  public broadcast(entry: Entry) {
    this.entriesApiService.broadcastEntry(entry.uid).subscribe();
  }

  public rescrape() {
    this.entriesApiService.forceProcessing().subscribe();
  }
}
