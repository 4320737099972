import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResultsComponent} from "./components/results/results.component";
import {PluginsComponent} from "./components/plugins/plugins.component";

const routes: Routes = [
  {
    path: '',
    component: ResultsComponent
  },
  {
    path: 'results',
    component: ResultsComponent
  }  ,
  {
    path: 'plugins',
    component: PluginsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
