import {Pipe, PipeTransform} from "@angular/core";
import {TimeSpan} from "@xws/ui";

@Pipe({
  name: 'agoTime'
})
export class AgoPipe implements PipeTransform {

  transform(value: Date): TimeSpan {
    return TimeSpan.fromMilliseconds(Date.now() - value.getTime());
  }

}
