<div class="flex column">
  <img src="assets/logo.svg" width="100px">
  <div class="input mb-2 mt-8">
    <xws-icon name="user_solid"></xws-icon>
    <input [disabled]="authService.isProcessing" [(ngModel)]="authenticationRequest.userName" id="username"
           name="userName" placeholder="Username"
           required tabindex="1" type="text" xwsAutoFocus>
  </div>
  <div class="input mb-2">
    <xws-icon name="lock_solid"></xws-icon>
    <input [disabled]="authService.isProcessing" [(ngModel)]="authenticationRequest.password" id="password"
           name="password" placeholder="Password" required
           tabindex="2" type="password">
  </div>
  <div class="flex">
    <button [disabled]="authService.isProcessing"
            (click)="authService.login(authenticationRequest)"
            class="fluid submit" tabindex="3">Login
    </button>
  </div>
</div>
<div class="message red" *ngIf="authService.error">{{authService.error}}</div>
