import {
  ChangeDetectionStrategy,
  Component,
  Output
} from "@angular/core";
import {NEVER, Observable} from "rxjs";
import {AppService} from "../../app.service";

@Component({
  selector: "app-pull-to-refresh",
  templateUrl: "./pull-to-refresh.component.html",
  styleUrls: ["./pull-to-refresh.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PullToRefreshComponent {
  @Output()
  readonly pulled: Observable<void> = NEVER;

  public constructor(public readonly appService: AppService
  ) {
    appService.isLoaded$.subscribe(isLoaded => {

    })
  }
}
