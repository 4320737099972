import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {AuthenticateRequest} from "../../api-models";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public authenticationRequest = new AuthenticateRequest();

  public constructor(public readonly authService: AuthService) {
  }

  ngOnInit(): void {
  }

}
