import {Component} from '@angular/core';
import {MessageBoxService} from '@xws/ui';
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public constructor(
    public readonly authService: AuthService,
    public readonly messageBoxService: MessageBoxService) {
  }
}
