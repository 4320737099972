<app-loader-android [loadLevel]="(this.position.nextTop- 60) /2 "></app-loader-android>
<xws-infinite-scroll (isFilledChange)="onFilledChanged($event)" class="entries" #scroller
                     [isFilled] xws-thumb (start)="onThumbStart($event)" (end)="onThumbEnd($event)"
                     (change)="onThumbChanged($event)">
  <ng-container *ngFor="let entry of appService.entries">
    <div class="entry" [class.opacity-30]="!entry.isAvailable" *ngIf="!(!entry.isAvailable && appService.hideSold)">
      <a [href]="entry.url" target="_blank" class="image"
         [style.background-image]="'url('+'api/images/' + entry.plugin + '/' + entry.imageFileName+')'">
        <h4>{{entry.pluginTitle}}
          <small>{{entry.collectedOn|localizedDateTime}}</small>
        </h4>
      </a>
      <div class="flex">
        <div class="details">
          <div class="flex align-items center">
            <div class="new-indicator" *ngIf="entry.isNew">NEW!</div>
            {{entry.address}}</div>
          <h4><a [href]="entry.url" target="_blank">{{entry.title}}</a></h4>
          <div class="flex">
            <div class="specs">
              <div *ngIf="entry.size > 0"><strong>Size</strong><h4>{{entry.size}}m²</h4></div>
              <div class="ml" *ngIf="entry.rooms > 0"><strong>Rooms</strong><h4>{{entry.rooms}}</h4></div>
              <div class="ml" *ngIf="entry.price > 0"><strong>Price</strong><h4>{{entry.price | localizedNumber}}€</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tools">
          <button class=" circular-icon-button favorite" (click)="appService.toggleFavorite(entry)">
            <xws-icon [name]="entry.isFavorite ? 'star_solid': 'star_light'"></xws-icon>
          </button>
          <button class=" circular-icon-button ml-1" (click)="appService.broadcast(entry)">
            <xws-icon name="paper_plane"></xws-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <button class="loader-entry" (click)="appService.loadAdditionalEntries(10)">
    Load more...
  </button>
</xws-infinite-scroll>
