<button (click)="refreshStates()">Refresh</button>
<div *ngFor="let plugin of pluginStates">
  <h4>{{ plugin.pluginName }}</h4>
  <div>{{ plugin.collectedItems }}</div>
  <div>{{ plugin.processedOn }}</div>
  <div *ngIf="plugin.errors.length > 0">
    <div>Errors:</div>
    <div *ngFor="let error of plugin.errors">
      <div>
        <div class="strong">{{ error.timestamp }}</div>
        {{ error.message }}
      </div>
    </div>
  </div>
  <hr/>
</div>
