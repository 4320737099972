import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {catchError} from "rxjs/operators";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(private readonly authService: AuthService) {
  }

  public intercept(originalRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const request: HttpRequest<unknown> = originalRequest.clone({
      setHeaders: {
        Authorization: 'bearer ' + this.authService.encodedToken || '',
        'Accept-Version': '1',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
          return of(undefined);
        }
        return throwError(error);
      })
    );
  }
}
