<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <ng-container *tuiRepeatTimes="let index of steps">
    <g *ngIf="isShown(index)" [attr.transform]="calculateTransform(index)">
      <rect x="47" y="22" rx="7.05" ry="3.3" width="6" height="16" fill="#c7c9cc">
        <animate *ngIf="finished" attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" repeatCount="indefinite"
                 [attr.begin]="calculateAnimationBegin(index)"></animate>
      </rect>
    </g>
  </ng-container>
</svg>
