import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from "@angular/core";
import {Math2} from "@xws/ui";

const HIDDEN_DISTANCE = 2;
const ROTATE_X_DEFAULT = 180;
const ROTATE_X_MAX = 500;
const ROTATE_X_MULTIPLIER = 2.3;

@Component({
  selector: "app-loader-android",
  templateUrl: "./loader-android.component.html",
  styleUrls: ["./loader-android.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderAndroidComponent {

  public loadLevelValue = 0;

  @Input()
  public set loadLevel(value: number) {
    this.loadLevelValue = value;
    this.topPosition = Math2.keepInRange(0, value, 68);
  }

  @HostBinding("class.hidden")
  public get hidden(): boolean {
    return this.loadLevelValue < HIDDEN_DISTANCE;
  }

  @HostBinding("style.top.px")
  public topPosition: number;


  public get transform(): string {
    const rotateX = Math.min(
      ROTATE_X_DEFAULT + this.loadLevelValue * ROTATE_X_MULTIPLIER,
      ROTATE_X_MAX
    );

    return `rotate(${rotateX} 0 0)`;
  }


}
