<div class="flex align-items center">
  <div class="logo">
    <img alt="homer-logo" src="assets/logo.svg">
  </div>
  <h4 class="ml-4">Homer</h4>
</div>
<button class="circular-icon-button">
  <xws-icon name="ellipsis_v"></xws-icon>
  <xws-context-menu [openOnLeftClick]="true">
    <xws-context-menu-item icon="list" [routerLink]="['plugins']">
      Plugins
    </xws-context-menu-item>
    <xws-context-menu-item icon="list" [routerLink]="['results']">
      Results
    </xws-context-menu-item>
    <xws-context-menu-item icon="redo" (click)="appService.reset()">
      Refresh
    </xws-context-menu-item>
    <xws-context-menu-item icon="search" (click)="appService.rescrape()">
      Rescrape
    </xws-context-menu-item>
    <xws-context-menu-item [icon]="appService.hideSold ? 'eye_slash':'eye'"
                           (click)="appService.hideSold = !appService.hideSold" [keepOpen]="true">
      <div class="flex">
        {{appService.hideSold ? 'Show sold items' : 'Hide sold items'}}
      </div>
    </xws-context-menu-item>
    <xws-context-menu-item [icon]="(subscriber.isSubscribed ? 'lightbulb_on' : 'lightbulb')" [keepOpen]="true"
                           (click)="subscriber.toggleSubscription()">
      <app-push-subscriber #subscriber></app-push-subscriber>
    </xws-context-menu-item>
    <xws-context-menu-item icon="user" (click)="authService.logout()">
      Logout {{authService.userName}}
    </xws-context-menu-item>
  </xws-context-menu>
</button>
