import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject, throwError} from "rxjs";
import {isJwtTokenValid, JwtToken} from "../models/jwt-token";
import {AuthApiService} from "../api-services";
import {JwtDecode} from "../models/jwt-decode";
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {AuthenticateRequest} from "../api-models";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly TOKEN_SAVE_NAME = 'token';

  public encodedToken: string;
  private jwtToken: JwtToken;

  private readonly authorizedSubject = new ReplaySubject<boolean>(1);
  public readonly authorized = this.authorizedSubject.asObservable();
  public error: string;
  public userName: string;

  public constructor(private readonly authApiService: AuthApiService, private readonly router: Router) {

  }

  public initialize(): Observable<void> {
    this.encodedToken = localStorage.getItem(this.TOKEN_SAVE_NAME);
    this.jwtToken = JwtDecode.decodeToken(this.encodedToken);
    this.userName = this.jwtToken?.sub;
    if (isJwtTokenValid(this.jwtToken)) {
      this.authorizedSubject.next(true);
    } else {
      this.logout();
    }
    return of(undefined);
  }

  public isProcessing = false;

  public login(authRequest: AuthenticateRequest) {
    this.isProcessing = true;
    this.authApiService.authenticate(authRequest).subscribe(token => {
      this.error = undefined;
      this.encodedToken = token;
      this.jwtToken = JwtDecode.decodeToken(token);
      this.userName = this.jwtToken?.sub;
      localStorage.setItem(this.TOKEN_SAVE_NAME, this.encodedToken);
      this.authorizedSubject.next(true);
      this.isProcessing = false;
    }, error => {
      this.isProcessing = false;
      this.error = 'Invalid login information';
    })
  }

  public logout(): Observable<void> {
    this.encodedToken = undefined;
    this.jwtToken = undefined;
    localStorage.removeItem(this.TOKEN_SAVE_NAME);
    this.authorizedSubject.next(false);
    return of();
  }
}
