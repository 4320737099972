import {Component, ElementRef, OnInit} from '@angular/core';
import {EntriesApiService, PluginsApiService} from '../../api-services';
import {AppService} from "../../app.service";
import {PluginError, PluginState} from "../../api-models";

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.scss']
})
export class PluginsComponent implements OnInit {
  protected pluginStates: PluginState[] = [];

  public constructor(
    public readonly elementRef: ElementRef,
    public readonly pluginsApiService: PluginsApiService,
    public readonly appService: AppService) {
  }

  public ngOnInit(): void {
    this.refreshStates();
  }

  public refreshStates(): void {
    this.pluginsApiService.getPluginStates().subscribe((states: PluginState[]) => {
      this.pluginStates = states;
    });
  }
}

