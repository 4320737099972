import {Component, ElementRef, OnInit} from '@angular/core';
import {EntriesApiService} from '../../api-services';
import {AppService} from "../../app.service";
import {ThumbChangeEvent, ThumbEndEvent, TimespanFormatOptions, TouchOrMouseEvent} from "@xws/ui";

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  public dateTimeNow = new Date(Date.now());
  public agoOptions: TimespanFormatOptions = {useAgo: true};

  public constructor(
    public readonly elementRef: ElementRef,
    public readonly entriesApiService: EntriesApiService,
    public readonly appService: AppService) {
  }

  public ngOnInit(): void {
  }

  public onFilledChanged(isFilled: boolean): void {
    if (!isFilled) {
      this.appService.loadAdditionalEntries(10);
    }
  }

  public isThumbed = false;
  public position = {top: 0, topDelta: 0, nextTop: 0};

  public onThumbStart(thumbStart: TouchOrMouseEvent) {
    this.isThumbed = true;
    this.position.top = this.elementRef.nativeElement.getBoundingClientRect().top;
  }

  public onThumbChanged(thumbChangeEvent: ThumbChangeEvent) {
    if (!this.isThumbed)
      return;

    this.position.topDelta = thumbChangeEvent.topOffset;
    this.position.nextTop = this.position.top + this.position.topDelta;
  }

  public onThumbEnd(thumbEndEvent: ThumbEndEvent) {
    this.isThumbed = false;
    if(this.position.nextTop > 80)
      this.appService.reset();
    this.position.nextTop = 0;
  }
}
