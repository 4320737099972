<svg *ngIf="loadLevelValue < 68; else loading" width="24" height="24" viewBox="0 0 24 24" fill="none"
     xmlns="http://www.w3.org/2000/svg" [attr.transform]="transform">
  <defs>
    <mask id="mask-1">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21 12C21 7.03 16.97 3 12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C14.06 21 15.96 20.3 17.48 19.14L16.06 17.7C14.91 18.51 13.51 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12H16L20 16L24 12H21Z"
            fill="white"/>
    </mask>
  </defs>
  <g mask="url(#mask-1)">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21 12C21 7.03 16.97 3 12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C14.06 21 15.96 20.3 17.48 19.14L16.06 17.7C14.91 18.51 13.51 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12H16L20 16L24 12H21Z"
          fill="black"/>
  </g>
</svg>
<ng-template #loading>
  <xws-icon [inline]="true" name="redo"></xws-icon>
  Refresh...
</ng-template>
